var YouLi = YouLi ? YouLi : {};
YouLi.Common = YouLi.Common ? YouLi.Common : {};
YouLi.Common.Utils = YouLi.Common.Utils ? YouLi.Common.Utils : {};

(function (YouLi) {
    (function (Common) {
        (function (Utils) {



            YouLi.Common.Utils.MimeType = function (extension) {
                var types = {
                    "jpg": "image/jpeg",
                    "jpeg": "image/jpeg",
                    "png": "image/png",
                    "ico": "image/x-icon",
                    "svg": "image/svg+xml",
                    "pdf": "application/pdf",
                    "doc": "application/msword",
                    "docx": "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                    "csv": "text/csv",
                    "xls": "application/vnd.ms-excel",
                    "xlsx": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                    "numbers": "application/vnd.apple.numbers",
                    "pages": "application/vnd.apple.pages",
                    "txt": "text/plain",
                };

                return types[extension.toLowerCase()];
            };

            YouLi.Common.Utils.IsMobile = function () {
                return navigator.userAgent.match(/(iPhone|iPod|iPad|Android|webOS|BlackBerry|IEMobile|Opera Mini)/i);
            }

            YouLi.Common.Utils.IsYouLiBundler = function () {
                return navigator.userAgent.match(/youlibundler/i);
            }

            YouLi.Common.Utils.IsYouLiMobileApp = function () {
                return navigator.userAgent.match(/(youli trip)/i);
            }

            YouLi.Common.Utils.GetParameterFromURL = function (parameterName) {
                const urlParams = new URLSearchParams(window.location.search);

                return urlParams.get(parameterName);
            }

            YouLi.Common.Utils.AddParametersToURL = function (url, additionalParameters) {
                return url + (url.indexOf("?") == -1 ? "?" : "&") + additionalParameters;
            };

            YouLi.Common.Utils.AddorUpdateParameterToURL = function (url, key, value) {

                // Note: the URL in the JavaScript API is case-sensitive, because URLs are case sensitive, so
                // we convert all existing params to lower-case and then also use the lowercase of our new key.
                // Be aware of this when consuming this function! In our .NET world we are not so concerned because we can handle all cases
                const urlObject = new URL(url);
                const params = new URLSearchParams(urlObject.search);

                const lowercaseParams = new URLSearchParams();
                for (const [name, value] of params) {
                    lowercaseParams.append(name.toLowerCase(), value);
                }
                
                lowercaseParams.set(key.toLowerCase(), value);

                urlObject.search = lowercaseParams.toString();

                return urlObject.href;
            };

            YouLi.Common.Utils.GetDateFormatStrings = function () {
                if (window.navigator.language === "en-US")
                    return ['MM-DD-YYYY', 'MM/DD/YYYY', 'MM-DD-YY', 'MM/DD/YY'];

                return ['DD-MM-YYYY', 'DD/MM/YYYY', 'DD-MM-YY', 'DD/MM/YY'];
            }

            YouLi.Common.Utils.TrimToSize = function (strInput, intTrimTo, boolIncDots) {

                let resultString = strInput;

                if (resultString) {

                    if (resultString.length > intTrimTo) {
                        resultString = resultString.substring(0, intTrimTo - 1);
                        if (boolIncDots) {
                            resultString += "&hellip;";
                        }
                    }
                }
                return resultString;
            }

            YouLi.Common.Utils.StripHTML = function(input)
            {
                return input.replace(/<.*?>/g, "");
            }

            YouLi.Common.Utils.GetFileExtension = function (filename) {
                return filename.split('.').pop().toLowerCase();
            };

        }(YouLi.Common.Utils ? YouLi.Common.Utils : {}));
    }(YouLi.Common ? YouLi.Common : {}));
}(YouLi ? YouLi : {}));