var YouLi = YouLi ? YouLi : {};
YouLi.Common = YouLi.Common ? YouLi.Common : {};
YouLi.Common.UI = YouLi.Common.UI ? YouLi.Common.UI : {};

(function (YouLi) {
    (function (Common) {
        (function (UI) {

            let loadingCounter = 0;
            let backDropElement;

            YouLi.Common.UI.IsAdvancedUpload = function () {
                var div = document.createElement('div');
                return (('draggable' in div) || ('ondragstart' in div && 'ondrop' in div)) && 'FormData' in window && 'FileReader' in window;
            }();

            YouLi.Common.UI.ShowStandardDialog = function (messageHTML, title, buttonText, status, buttonClickCallback, includeCancelButton = false) {

                $("#StandardDialog .modal-body .page-dialogue__message").html(messageHTML);
                $("#StandardDialog .modal-body .page-dialogue__title").text(title || "Oops!");
                $("#StandardDialog .modal-body .action-button").text(buttonText || "Okay");

                $("#StandardDialog .modal-body .btn.action-button").off('click');
                if (buttonClickCallback) {
                    $("#StandardDialog .modal-body .btn.action-button").on('click', function () {
                        buttonClickCallback();
                    });
                }

                $("#StandardDialog .modal-body .btn.cancel-button").toggle(includeCancelButton);

                // clear any current style and add the required one for action button and header
                $("#StandardDialog .modal-body .action-button").removeClass("btn-primary btn-action btn-invitee");
                $("#StandardDialog .modal-header").removeClass("warning success")

                if (status === "success") {
                    $("#StandardDialog .modal-body .action-button").addClass("btn-invitee");
                    $("#StandardDialog .modal-header").addClass(status);
                }
                else if (status === "warning") {
                    $("#StandardDialog .modal-body .action-button").addClass("btn-action");
                    $("#StandardDialog .modal-header").addClass(status);
                }
                else {
                    $("#StandardDialog .modal-body .action-button").addClass("btn-primary");
                    $("#StandardDialog .modal-header");
                }

                $("#StandardDialog").modal();
            };

            YouLi.Common.UI.HideStandardDialog = function () {
                $("#standardDialog").modal('hide');
            };

            YouLi.Common.UI.ShowStaticStandardDialogNoClose = function (messageHTML, title) {

                $("#StandardDialog .modal-body .page-dialogue__message").html(messageHTML);
                $("#StandardDialog .modal-body .page-dialogue__title").text(title || "Oops!");

                // always an error
                $("#StandardDialog .modal-header").removeClass("warning success");

                // remove the button, we don't allow closing this dialog
                $("#StandardDialog .modal-body .btn").remove();

                $("#StandardDialog").modal({ backdrop: 'static', keyboard: false });
            };

            YouLi.Common.UI.HideStandardDialog = function () {
                $("#standardDialog").modal('hide');
            };



            // If ShowLoading gets called multiple times when one is already showing, it will create a new one, and when Hideloading is called, it will just remove the last created spinner.
            // This avoids issues with it getting confused with the Bootstrap modal overlay, which gets created by BS and handled completely by BS. Although they look the same, we don't want
            // to mix up our spinner overlays with the bootstrap modal ones.
            YouLi.Common.UI.ShowLoading = function () {

                if (loadingCounter === 0) {
                    backDropElement = $(document.createElement('div'))
                        .addClass('modal-backdrop fade spinner')
                        .appendTo($('body'))
                        .addClass("in");
                }
                loadingCounter++;
            };

            YouLi.Common.UI.HideLoading = function () {
                loadingCounter = loadingCounter > 0 ? loadingCounter - 1 : 0;

                if (loadingCounter === 0 && backDropElement) {
                    backDropElement.remove();
                }
            };

            YouLi.Common.UI.ShowButtonLoading = function (element) {
                $(element).prop("disabled", true);
                $(element).addClass("spinner");
            };

            YouLi.Common.UI.ShowAvatarLoading = function (element) {
                $(element).prop("disabled", true);
                $(element).parent().addClass('spinner-avatar');
            };

            YouLi.Common.UI.ShowInputLoading = function (element) {
                $(element).prop("disabled", true);
                $(element).addClass("input-spinner");
            };

            YouLi.Common.UI.HideButtonLoading = function (element) {
                $(element).prop("disabled", false);
                $(element).removeClass("spinner");
            };

            YouLi.Common.UI.HideAvatarLoading = function (element) {
                $(element).prop("disabled", false);
                $(element).parent().removeClass('spinner-avatar');
            };

            YouLi.Common.UI.HideInputLoading = function (element) {
                $(element).prop("disabled", false);
                $(element).removeClass("input-spinner");
            };

            YouLi.Common.UI.ShowLoadingOnBlockElement = function (element) {
                $(element).addClass("loading").addClass("spinner");
            }

            YouLi.Common.UI.HideLoadingOnBlockElement = function (element) {
                $(element).removeClass("loading").removeClass("spinner");
            }

            YouLi.Common.UI.ScrollToTopModal = function (modalId) {
                const modalElement = document.getElementById(modalId);
                if (modalElement)
                    modalElement.scrollTo(0, 0);
            };

            $(document).on("submit", ".submit-causes-load", function () {

                var submitBtn = $(this).find("button[type='submit']");
                if (submitBtn.is(":disabled")) {
                    return false;   // fail-safe to prevent multiple clicks from being processed
                }
                YouLi.Common.UI.ShowButtonLoading(submitBtn);

                // do the same for any input[submit] buttons
                var submitIpt = $(this).find("input[type='submit']");
                if (submitIpt.is(":disabled")) {
                    return false;   // fail-safe to prevent multiple clicks from being processed
                }
                YouLi.Common.UI.ShowInputLoading(submitIpt);

            });

            YouLi.Common.UI.FocusText = function () {
                var input = this;
                setTimeout(function () {
                    input.selectionStart = 0;
                    input.selectionEnd = input.value.length;
                }, 100);
            };

            YouLi.Common.UI.CompleteTrial = function () {

                $.ajax({
                    url: "/manage/marktrialcompleted",
                    type: "POST",
                    dataType: "json",
                    contentType: "application/json; charset=utf-8",
                    complete: function (response) {
                        // doing nothing
                    }
                });
            };

            YouLi.Common.UI.ClearClientSideErrors = function (container) {
                if (!container)
                    container = $(document);

                $(".has-error", container).removeClass("has-error");

                $(".field-validation-error", container).removeClass("field-validation-error").addClass("field-validation-valid");

                $('div[data-valmsg-summary=true]', container).removeClass("validation-summary-errors").addClass("validation-summary-valid");

            };


            YouLi.Common.UI.FormatClientSideErrors = function (errors, xhr) {

                // update the summary as well
                var summaryContainer = $('div[data-valmsg-summary=true]'),
                    list = summaryContainer.find("ul");

                if (errors == "" || (xhr && xhr.status == 500)) {
                    if (list)
                        list.empty();
                    summaryContainer.addClass("validation-summary-errors").removeClass("validation-summary-valid");
                    return;
                }

                $.each(errors, function (key, val) {
                    var container = $('span[data-valmsg-for="' + key + '"]');
                    container.removeClass("field-validation-valid").addClass("field-validation-error");
                    container.html(val[val.length - 1].ErrorMessage);

                    $(".field-validation-error").closest(".form-group").addClass("has-error").removeClass("has-success");
                });

                if (list && list.length && errors) {
                    list.empty();
                    summaryContainer.addClass("validation-summary-errors").removeClass("validation-summary-valid");

                    $.each(errors, function () {
                        $("<li />").html(this[this.length - 1].ErrorMessage).appendTo(list);
                    });
                }
            };


            YouLi.Common.UI.InitDropDownWithSearch = function () {
                $('.searchable-dropdown').each(function () {
                    let element = $(this);
                    if (!element.parent().hasClass("input-group")) {
                        element.wrap("<div class='input-group'></div>");

                        let btnSection = `<span class="input-group-btn select2-btn">
                            <button class="btn btn-default" type="button" data-select2-open ="${element.attr('id')}">
                                <span class="glyphicon glyphicon-search"></span>
                            </button>
                        </span>`
                        $(btnSection).insertAfter(element);
                        element.select2({
                            theme: "bootstrap"
                        });
                    }
                });

                $("button[data-select2-open]").on('click', function () {
                    $("#" + $(this).data("select2-open")).select2("open");
                });
            }

            YouLi.Common.UI.InitCkeditor = function (target, removebuttons, s3BucketPath, isLimitedVersion, s3BucketHash) {

                let editor = null;
                let ckEditorInstance = CKEDITOR.instances[target];

                //Had to destroy CKEditor and attach it again if it has already been binded to the element to fix the issue for setting the value of text without error when trying to open the popup for more than once without page refresh
                if (ckEditorInstance) {
                    console.log("Destroying a CKEditor instance");
                    ckEditorInstance.destroy();
                    ckEditorInstance = undefined;
                }

                if (isLimitedVersion) {
                    removebuttons += ',Image,ImgPen,Table,Smiley,SpecialChar,Maximize,Embed,YouTube,Anchor';

                    CKEDITOR.replace(target, {
                        height: 100,
                        removeButtons: removebuttons,
                        disableNativeSpellChecker: false,
                        format_tags: 'p;h1;h2;h3',
                        embed_provider: '//ckeditor.iframe.ly/api/oembed?url={url}&callback={callback}&api_key=9e0daab0bb19469133da69',
                        useAutoToolbar: false,
                        extraPlugins: "N1ED-editor",
                        apiKey: 'ZRJDa7nT'
                    });

                } else {
                    CKEDITOR.replace(target, {
                        height: 100,
                        removeButtons: removebuttons,
                        disableNativeSpellChecker: false,
                        format_tags: 'p;h1;h2;h3',
                        embed_provider: '//ckeditor.iframe.ly/api/oembed?url={url}&callback={callback}&api_key=9e0daab0bb19469133da69',
                        extraPlugins: "file-manager",
                        apiKey: 'ZRJDa7nT',
                        ui: {
                            useAutoToolbar: false
                        },
                        Flmngr: {
                            accessKey: "ZRJD231556E286DDB42A7F0C50BAD72B24CD",
                            urlFileManager: ` https://files.flmngr.com/flmngr-beta/?dir=${s3BucketPath}&hash=${s3BucketHash}`,
                            urlFiles: `https://ylt-planner-editorimages.s3.us-east-1.amazonaws.com/${s3BucketPath}`,
                            apiKeyUnsplash: '65f64eeaa6d3a0b53537d09fd977ac2bb484db2d8065e9c9c4ffa6617bc82e1b'
                        }
                    });
                }
            }


            YouLi.Common.UI.Init = function () {

                $(".copy-menu-link").copyToClipboard();

                $('#AcceptPrivacyUpdate').off('click').on('click', function () {

                    if ($('#IsPrivacyUpdateAccepted').length) {
                        $('#IsPrivacyUpdateAccepted').val('true');
                        $('.privacy-content').addClass('hidden');
                    } else {
                        $.ajax({
                            url: "/manage/acceptprivacyupdate",
                            type: "POST",
                            dataType: "json",
                            contentType: "application/json; charset=utf-8",
                            complete: function (response) {
                                $('.privacy-content').addClass('hidden');
                            }
                        });
                    }
                });


                //This code is for stopping the video playing on background when closing a popup with video playing on it
                $('.standardDialog').on('hidden.bs.modal', function (e) {
                    if ($(".standardDialog iframe")) {
                        $(".standardDialog iframe").attr("src", $(".standardDialog iframe").attr("src"));
                    }
                });

                YouLi.Validation.Init();
                YouLi.Common.UI.InitDropDownWithSearch();

                $(document).off("click", ".upgrade-subscription-button").on("click", ".upgrade-subscription-button", function () {
                    var title = $(this).data("upgrade-title");
                    var message = $(this).data("upgrade-message");
                    var confirmTitle = $(this).data("upgrade-confirm-title");
                    var confirmMessage = $(this).data("upgrade-confirm-message");
                    var tripId = $(this).data("upgrade-trip-id");
                    var target = $(this).attr("target");
                    YouLi.UpgradeSubscription.GoToUpgradePage(title, message, confirmTitle, confirmMessage, false, '', tripId, target);
                });

                // Developer note:  the following two statements both initialise tooltip in a different way.  We need to look at the impact to see if we should just do the second one, or if we should 
                // be restricting the second one to be only for .helper elements.
                $('[data-toggle="tooltip"]:not(".helper")').tooltip();

                $('[data-toggle="tooltip"]').tooltip({
                    trigger: "hover focus",
                    container: "body"
                });

                $('body > .popover').remove();

                $('[data-toggle="popover"]:not(".custom")').popover();

                //$('table [data-toggle="popover"]').on("show.bs.popover", function () {

                //    $('.popover').css('left', $("this")).offset().left + 5);
                //});

                $(".scroll-to-top").on("click", function () {

                    $('html, body').animate({ scrollTop: 0 }, 800);

                });

                // Datepicker
                $(".datepicker").datetimepicker({
                    format: 'D MMM YYYY',
                    extraFormats: YouLi.Common.Utils.GetDateFormatStrings(),
                    keepOpen: false,
                    useCurrent: false,
                    ignoreReadonly: true,
                    widgetPositioning: {
                        horizontal: 'auto',
                        vertical: 'bottom'
                    }
                });

                $(".datetimepicker").datetimepicker({
                    format: 'D MMM YYYY hh:mm A',
                    extraFormats: YouLi.Common.Utils.GetDateFormatStrings(),
                    keepOpen: false,
                    useCurrent: false,
                    ignoreReadonly: true,
                    widgetPositioning: {
                        horizontal: 'auto',
                        vertical: 'bottom'
                    },
                    icons: {
                        time: 'time-custom-icon'
                    }
                });

                $(".timepicker").datetimepicker({
                    format: 'hh:mm A',
                    extraFormats: YouLi.Common.Utils.GetDateFormatStrings(),
                    keepOpen: false,
                    useCurrent: false,
                    ignoreReadonly: true,
                    widgetPositioning: {
                        horizontal: 'right',
                        vertical: 'bottom'
                    }
                });

                if ($.fn.daterangepicker) {
                    // Configure the date range picker - but ONLY if it's not already configured! Otherwise we lose defaults that have already been setup
                    let localeConfig = {
                        format: 'D MMM YYYY',
                        applyLabel: 'Submit',
                        cancelLabel: 'Clear',
                        fromLabel: 'From',
                        toLabel: 'To',
                        customRangeLabel: 'Custom Date Range',
                        daysOfWeek: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
                        monthNames: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
                        firstDay: 1
                    }

                    $('.daterangepicker-input').daterangepicker({
                        autoUpdateInput: true,
                        startDate: moment(),
                        endDate: moment(),
                        ranges: {
                            'Today': [moment(), moment()],
                            'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                            'Last 7 Days': [moment().subtract(6, 'days'), moment()], // Today + last 6 days
                            'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                            'Last 60 Days': [moment().subtract(59, 'days'), moment()],
                            'Last 90 Days': [moment().subtract(89, 'days'), moment()],
                            'Last 6 Months': [moment().subtract(6, 'month').startOf('month'), moment()],
                            'Last 12 Months': [moment().subtract(12, 'month').startOf('month'), moment()],
                            'Last Week': [moment().subtract(1, 'week').startOf('isoweek'), moment().subtract(1, 'week').endOf('isoweek')],
                            'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
                            'Last Quarter': [moment().subtract(1, 'quarter').startOf('quarter'), moment().subtract(1, 'quarter').endOf('quarter')],
                            'Last Year': [moment().subtract(1, 'year').startOf('year'), moment().subtract(1, 'year').endOf('year')]
                        },
                        opens: 'left',
                        separator: ' to ',
                        locale: localeConfig
                    });

                    $('.daterangepicker-input-future').daterangepicker({
                        autoUpdateInput: true,
                        startDate: moment(),
                        endDate: moment().add(1, 'year'),
                        ranges: {
                            'Today': [moment(), moment()],
                            'Tomorrow': [moment().add(1, 'days'), moment().add(1, 'days')],
                            'Next 7 Days': [moment(), moment().add(6, 'days')],
                            'Next 30 Days': [moment(), moment().add(29, 'days')],
                            'Next 60 Days': [moment(), moment().add(59, 'days')],
                            'Next 90 Days': [moment(), moment().add(89, 'days')],
                            'Next 6 Months': [moment(), moment().add(6, 'month')],
                            'Next 12 Months': [moment(), moment().add(12, 'month')],
                            'Next Week': [moment().add(1, 'week').startOf('isoweek'), moment().add(1, 'week').endOf('isoweek')],
                            'Next Month': [moment().add(1, 'month').startOf('month'), moment().add(1, 'month').endOf('month')],
                            'Next Quarter': [moment().add(1, 'quarter').startOf('quarter'), moment().add(1, 'quarter').endOf('quarter')],
                            'Next Year': [moment().add(1, 'year').startOf('year'), moment().add(1, 'year').endOf('year')],
                            'Next 5 Years': [moment(), moment().add(5, 'year').endOf('year')]
                        },
                        opens: 'left',
                        separator: ' to ',
                        locale: localeConfig
                    });
                }



                YouLi.Common.UI.ClearableInputInit();


                //Fix for issue with ckeditor in Bootstrap modal
                $.fn.modal.Constructor.prototype.enforceFocus = function () {
                    var $modalElement = this.$element;
                    $(document).on('focusin.modal', function (e) {
                        var $parent = $(e.target.parentNode);
                        if ($modalElement[0] !== e.target
                            && !$modalElement.has(e.target).length
                            && $(e.target).parentsUntil('*[role="dialog"]').length === 0) {
                            $modalElement.focus();
                        }
                    });
                };


                // force "_blank" for all links in CKEditor
                if (typeof CKEDITOR !== 'undefined') {
                    CKEDITOR.on('dialogDefinition', function (ev) {
                        try {
                            /* this just gets the name of the dialog */
                            var dialogName = ev.data.name;
                            /* this just gets the contents of the opened dialog */
                            var dialogDefinition = ev.data.definition;

                            /* Make sure that the dialog opened is the link plugin ... otherwise do nothing */
                            if (dialogName == 'link') {
                                /* Getting the contents of the Target tab */
                                var informationTab = dialogDefinition.getContents('target');
                                /* Getting the contents of the dropdown field "Target" so we can set it */
                                var targetField = informationTab.get('linkTargetType');
                                /* Now that we have the field, we just set the default to _blank
                                A good modification would be to check the value of the URL field
                                and if the field does not start with "mailto:" or a relative path,
                                then set the value to "_blank" */
                                targetField['default'] = '_blank';
                            }
                            if (dialogName == 'image') {
                                //Setting up the customized labels
                                dialogDefinition.contents[0].elements[0].children[0].children[0].label = 'Image URL';
                                dialogDefinition.contents[0].elements[0].children[0].children[1].label = 'Choose Image';
                                dialogDefinition.contents[1].elements[1].className = 'hidden';
                                dialogDefinition.contents[2].elements[0].label = 'CHOOSE IMAGE TO UPLOAD';
                                dialogDefinition.contents[2].elements[1].label = 'UPLOAD FILE';
                            }
                        } catch (exception) {
                            alert('Error ' + ev.message);
                        }
                    });
                }

                //// Detect ios 11_0_x affected 
                //// NEED TO BE UPDATED if new versions are affected
                //var ua = navigator.userAgent,
                //    iOS = /iPad|iPhone|iPod/.test(ua),
                //    iOS11 = /OS 11_0_1|OS 11_0_2|OS 11_0_3|OS 11_1/.test(ua);

                //// ios 11 bug caret position
                //if (iOS && iOS11) {

                //    // Add CSS class to body
                //    $("body").addClass("iosBugFixCaret");

                //}

            };
            YouLi.Common.UI.ApplyGangColors = function () {
                //This code adds different color based on data value for each and every gang
                $('.colorize').each(function () {
                    var color = $(this).data("color");
                    $(this).css({ "background-color": color });
                    // $(this).siblings(".gang-name").css({ "color": color });
                    $(this).removeClass("colorize");
                });

                $(".toolitip-gang").popover({
                    html: true,
                    sanitize: false,
                    trigger: "hover",
                    content: function () {
                        return $(this).siblings(".tooltip-info").html();
                    },
                    container: "body"
                });
            };
            /** * Clearable text inputs */
            YouLi.Common.UI.ClearableInputInit = function () {
                $(document).off('input', '.clearable').on('input', '.clearable', function () {
                    $(this).toggleClass('x', this.value !== '');
                }).off('mousemove', '.x').on('mousemove', '.x', function (e) {
                    $(this).toggleClass('onX', this.offsetWidth - 18 < e.clientX - this.getBoundingClientRect().left);
                }).off('touchstart click', '.onX').on('touchstart click', '.onX', function (ev) {
                    ev.preventDefault();
                    $(this).removeClass('x onX').val('').change();
                });

                $('.clearable').each(function (index) {
                    if ($(this).val() !== '')
                        $(this).addClass('x');
                });

            }

            YouLi.Common.UI.GetClientWidth = function () {
                return Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
            }

            YouLi.Common.UI.LoadSvg = function (container) {
                let allSvg = $(container).find('.svg-icon:not(:has(svg))');

                $.each(allSvg, function (idx, val) {
                    const url = $(val).data('svg-url');
                    YouLi.Common.Get(url, null, function (response) {
                        $(val).append($(response).find('svg'));
                    }, null, 'xml', true);
                });
            }

            $(document).ready(function () {

                YouLi.Common.UI.Init();

                // bust the cache for all AJAX calls (especially IE)
                $.ajaxSetup({ cache: false });

            });

            $(document).on('click', '.helper-link', function (e) {
                if ($(this).data('suggestions-search') && (typeof zE !== "undefined")) {
                    e.stopPropagation();
                    zE('webWidget', 'helpCenter:setSuggestions',
                        { search: $(this).data('suggestions-search') });
                    $zopim.livechat.window.show();
                }
            });

        }(YouLi.Common.UI ? YouLi.Common.UI : {}));
    }(YouLi.Common ? YouLi.Common : {}));
}(YouLi ? YouLi : {}));