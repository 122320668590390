(function ($) {

    $.fn.copyToClipboard = function (options) {

        var self = $(this);

        var settings = $.extend({
            URLElementSelector: ".magicURL",
        }, options);

        CopyStringToClipboard = function (str) {
            // Create new element
            var el = document.createElement('textarea');
            // Set value (string to be copied)
            el.value = str;
            document.body.appendChild(el);
            //cheching if device is iOS(iphone/ipad) then use this logic to copy the code in a range and then 
            //select that range as it is not working as it supposed to for iOS devices 
            var isiOS = navigator.userAgent.match(/ipad|iphone/i);
            if (isiOS) {
                range = document.createRange();
                range.selectNodeContents(el);
                selection = window.getSelection();
                selection.removeAllRanges();
                selection.addRange(range);
                el.setSelectionRange(0, 999999);
            } else {
                // Set non-editable to avoid focus and move outside of view
                el.setAttribute('readonly', '');
                el.style = { position: 'absolute', left: '-9999px' };
                document.body.appendChild(el);
                // Select text inside element
                el.select();
            }
            // Copy text to clipboard
            try {
                document.execCommand('copy');
            }
            catch (err) {
                alert('please press Ctrl/Cmd+C to copy');
            }
            // Remove temporary element
            document.body.removeChild(el);
        };

        $(function () {

            // Nb: the following is very basic - we have a more complicated version that we could/should incorporate into this in YouLi.ShareTripDialog.CopyStringToClipboard which does special
            // things to allow Safari to work

            self.off("click");
            self.on("click", function (e) {

                e.preventDefault();

                // pure copy of a provided string using data-link-url
                if ($(this).data("link-id")) {
                    var linkToCopy = $($(this).data("link-id")).attr("href");
                    CopyStringToClipboard(linkToCopy);
                    return;
                }

                // find target element
                let inp = $(settings.URLElementSelector);

                // is element selectable?
                if (inp && inp.select) {

                    // select text
                    inp.select();

                    try {
                        // copy text
                        document.execCommand('copy');
                        inp.blur();
                    }
                    catch (err) {
                        alert('please press Ctrl/Cmd+C to copy');
                    }

                }
            });
        });

        return this;
    };

}(jQuery));